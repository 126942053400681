import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function gsapAnim(elements, toggleClass) {
    if (elements) {

        gsap.registerPlugin(ScrollTrigger);

        elements.forEach( (item) => {
            gsap.from(item, {
                scrollTrigger: {
                    start: 'top bottom-=15%',
                    end: 'bottom top',
                    trigger: item,
                    scrub: true,
                    //markers: true,
                    toggleClass: toggleClass,
                    once: true
                }
            });
        })
    }
}

function gsapStaggerBlocksAnim(element) {
    if (element) {

        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.batch(element, {
            start: 'top bottom-=15%',
            onEnter: elements => {
                gsap.from(elements, {
                    autoAlpha: 0,
                    y: 50,
                    duration: 1,
                    stagger: 0.15
                });
            },
            once: true
        });
    }
}

function gsapTimelineAnimation(targets) {
    if (targets) {
        gsap.registerPlugin(ScrollTrigger);

        let mm = gsap.matchMedia()
        mm.add({
            isMobile: 'screen and (max-width: 767px)',
            isDesktop: 'screen and (min-width: 768px)',
        }, ((context) => {
            let {isMobile, isDesktop} = context.conditions;

            targets.forEach((target) => {
                const timelineHeadings = target.querySelectorAll('.timelineContent')
                const targetHeadings = gsap.utils.toArray(timelineHeadings);
                const timelineImage = target.querySelector('.timelineImage')

                const tlColour = gsap.timeline({paused: true});
                tlColour.from(target, {
                    '--timelineDotColour': '#4f5959',
                    duration: 0.5,
                });
                tlColour.from(targetHeadings, {
                    color: '#4f5959',
                    duration: 0.5,
                }, '<');

                const tlImage = gsap.timeline({paused: true});
                if (timelineImage) {
                    tlImage.from(timelineImage, {
                        opacity: 0,
                        y: 25,
                        duration: 1,
                    })
                }

                function timelinePlayAnimations() {
                    if(timelineImage) {
                        tlImage.play(0);
                    }
                    tlColour.play(0);
                }

                function timelineResetAnimations() {
                    if(timelineImage) {
                        tlImage.reverse()
                    }
                    tlColour.reverse()
                }

                const tl = gsap.timeline({
                    scrollTrigger: {
                        start: isMobile ? 'top center' : 'top center',
                        end: isMobile ? 'bottom center' : 'bottom center',
                        trigger: target,
                        scrub: true,
                        // markers: true,
                        onEnter: () => timelinePlayAnimations(),
                        onLeaveBack: () => timelineResetAnimations(),
                    }
                });

                tl.from(target, {
                    '--timelineHeight': '0%',
                    duration: 1,
                });
            });

        }))
    }
}

function gsapNumberParallaxAnim(parallaxList) {
    if(!parallaxList) return;

    let mm = gsap.matchMedia()
    mm.add({
        isMobile: 'screen and (max-width: 767px)',
        isDesktop: 'screen and (min-width: 768px)',
    }, ((context) => {
        let {isMobile, isDesktop} = context.conditions;

        const tl = gsap.timeline({
            scrollTrigger: {
                start: 'top bottom',
                end: 'bottom top',
                trigger: parallaxList,
                scrub: 2,
                // markers: true,
            }
        });

        tl.to(parallaxList, {
            top: isMobile ? '-5%' : '-20%',
        })
    }));
}

window.addEventListener('load', () => {
    const slideInLefts = gsap.utils.toArray('.slideInLeft');
    const slideInRights = gsap.utils.toArray('.slideInRight');
    const slideInBottoms = gsap.utils.toArray('.slideInBottom');
    const numberOnes = gsap.utils.toArray('.numberParallax');

    gsapAnim(slideInLefts, 'slide-in-left');
    gsapAnim(slideInRights, 'slide-in-right');
    gsapAnim(slideInBottoms, 'slide-in-bottom');
    gsapStaggerBlocksAnim('.slideInBottomStagger');
    gsapNumberParallaxAnim(numberOnes);

    const timeLineTrigger = document.querySelectorAll('.timelineTrigger');
    gsapTimelineAnimation(timeLineTrigger);
})
