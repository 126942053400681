const Flickity = require('flickity');
require('flickity-fade');
require('flickity-as-nav-for');
const sliders = document.querySelectorAll('.sliderContainer');

if (sliders) {

	sliders.forEach( (item) => {

		const slider = item.querySelector('.slider');
		const sliderProduct = item.querySelector('.productSlider');
		const sliderLogo = item.querySelector('.sliderLogo');
		const sliderQuote = item.querySelector('.sliderQuote');
		const sliderQuoteAsNavFor = item.querySelector('.sliderQuoteAsNavFor');
		const sliderBtnsDisableToggle = item.querySelectorAll('.slider-btn.selectedIndex');
		const sliderPrev = item.querySelector('.sliderPrev');
		const sliderNext = item.querySelectorAll('.sliderNext');

		if (slider) {
			let flkty = new Flickity(slider, {
				accessibility: true,
				prevNextButtons: false,
				pageDots: false,
				imagesLoaded: true,
				wrapAround: false,
				groupCells: 1,
				cellAlign: 'left',
				fade: slider.dataset.slider,
				on: {
					ready: function() {
						setTimeout( () => {
							setSliderHeightToMax(this);
						}, 100);
					}
				}
			})

			flktyFunctions(flkty);
		}

		if (sliderProduct) {
			let flkty = new Flickity(sliderProduct, {
				accessibility: true,
				prevNextButtons: false,
				pageDots: false,
				imagesLoaded: true,
				loop: true,
				cellAlign: 'left',
				fade: true,
				setGallerySize: false,
				on: {
					ready: function() {
						setTimeout( () => {
							setSliderHeightToMax(this);
						}, 100);
					}
				}
			})

			flktyFunctions(flkty);
		}

		if (sliderLogo) {
			let tickerSpeed = 1;

			let flkty = null;
			let isPaused = false;

			const update = () => {
				if (isPaused) return;
				if (flkty.slides) {
					flkty.x = (flkty.x - tickerSpeed) % flkty.slideableWidth;
					flkty.selectedIndex = flkty.dragEndRestingSelect();
					flkty.updateSelectedSlide();
					flkty.settle(flkty.x);
				}
				window.requestAnimationFrame(update);
			};

			const pause = () => {
				isPaused = true;
			};

			const play = () => {
				if (isPaused) {
					isPaused = false;
					window.requestAnimationFrame(update);
				}
			};

			flkty = new Flickity(sliderLogo, {
				imagesLoaded: true,
				autoPlay: false,
				prevNextButtons: false,
				pageDots: false,
				draggable: true,
				wrapAround: true,
				selectedAttraction: 0.015,
				friction: 0.25,
				on: {
					ready: function() {
						setTimeout( () => {
							setSliderHeightToMax(this);
						}, 100);
					}
				}
			})
			flkty.x = 0;

			addAutoScrollEventListeners(sliderLogo)

			console.log('sliderNext ', sliderPrev)

			if(sliderNext) {
				sliderNext.forEach(slider => {
					addAutoScrollEventListeners(slider)
				})
			}

			if(sliderPrev) {
				addAutoScrollEventListeners(sliderPrev)
			}

			function addAutoScrollEventListeners(element) {
				element.addEventListener('mouseenter', pause, false);
				element.addEventListener('focusin', pause, false);
				element.addEventListener('mouseleave', play, false);
				element.addEventListener('focusout', play, false);
			}

			flkty.on('dragStart', () => {
				isPaused = true;
			});

			update();

			flktyFunctions(flkty);
		}

		if (sliderQuote) {
			let flkty = new Flickity(sliderQuote, {
				accessibility: true,
				prevNextButtons: false,
				pageDots: false,
				imagesLoaded: true,
				loop: true,
				wrapAround: false,
				cellAlign: 'left',
				groupCells: true,
				fade: true,
				on: {
					ready: function() {
						setTimeout( () => {
							setSliderHeightToMax(this);
						}, 100);
					}
				}
			})

			flktyFunctions(flkty);
		}

		if (sliderQuoteAsNavFor) {
			let flkty = new Flickity(sliderQuoteAsNavFor, {
				prevNextButtons: false,
				pageDots: false,
				asNavFor: document.querySelector('.sliderQuote'),
				groupCells: true,
				imagesLoaded: true,
				freeScroll: true,
				on: {
					ready: function() {
						setTimeout( () => {
							setSliderHeightToMax(this);
						}, 100);
					}
				}
			})

			flktyFunctions(flkty);
		}

		function setSliderHeightToMax(slider) {
			slider.cells.forEach(cell => cell.element.style.height = '');

			let heights = slider.cells.map(cell => cell.element.offsetHeight),
				max = Math.max.apply(Math, heights);

			slider.cells.forEach(cell => cell.element.style.height = max + 'px');
		}

		function flktyFunctions(flkty) {
			if(sliderPrev) {
				sliderPrev.addEventListener('click', () => {
					flkty.previous();
				});
			}

			if(sliderNext) {
				sliderNext.forEach( (item) => {
					item.addEventListener('click', () => {
						flkty.next();
					});
				})
			}

			window.addEventListener('resize', () => {
				setSliderHeightToMax(flkty);
			});

			window.addEventListener('load', () => {

				setTimeout( () => {
					setSliderHeightToMax(flkty);
					flkty.resize();
				}, 150);
			});
		}
	})
}
