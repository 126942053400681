import { CountUp } from 'countup.js';
const countUps = document.querySelectorAll('.countUp');

if (countUps) {

	countUps.forEach( (item) => {
		const countUp = new CountUp(item, item.innerHTML, {
			enableScrollSpy: true,
			scrollSpyOnce: true
		});
	})
}
